<template>
    <div class="container mt-4 mb-4">
        <router-view />
    </div>
</template>

<script>
export default {
    
};
</script>